@import "../../styles/variables.scss";

.password-rules {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  margin-top: 8px;
  width: 86%;
  right: 7%;
  border-radius: 6px;
  background-color: $teal;
  color: $gray--light;
  &:before {
    content: "";
    position: absolute;
    top: -8px;
    left: 18%;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 9px solid $teal;
  }
  @media (max-width: 350px) {
    top: -17rem;
    &:before {
      top: 132px;
      left: 50% !important;
      border-bottom: none;
      border-top: 9px solid $teal;
    }
  }
}

.is-open {
  visibility: visible;
}

.right-arrow {
  &:before {
    left: 78%;
  }
}

.password-list {
  list-style: circle outside;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.83px;
  font-style: normal;
  font-weight: 400;
  margin: 1rem 0;
  @media (min-width: 351px) {
    columns: 2;
    column-gap: 1.5em;
  }
}

.li-checked {
  list-style: georgian outside url("../../assets/icons/checkmark-simple.svg");
}
