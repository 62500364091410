.status-banner-container {
  position: relative;
  max-width: 1600px;
  display: flex;
  height: 5rem;
}

.double-height {
  height: 10rem;
}

.status-banner {
  display: flex;
  align-items: center;
  z-index: 2;
  font-weight: 800;
  font-size: .8rem;
  background-color: #f2f2f3;
  border-left: solid 0.3rem #ed5623;
  padding: 0.5rem;
  margin-top: 1rem;
  border-radius: 5px;
  width: 100%;
}

.icon {
  margin: .5rem;
  margin-right: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
}

.close-btn {
  display: flex;
  border: 1px solid transparent;
  background-color: transparent;
  margin-left: auto;
}

.close-btn svg {
  width: 12px;
  height: 12px;
}

.message small {
  font-weight: 300;
  font-size: .8rem;
  color: #000;
}
