@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

$login-content-width: 88%;
$input-text-color: rgba(235, 235, 245, 0.6);
/*
 background color was originally rgba(120, 120, 128, 0.36);
 but it has been replaced with the full opacity equivalent of rgb(46, 71, 84)
 so that the colors look the same when layered
 */
$input-background-color: rgb(46, 71, 84);

// shared styles accross clients/modes
.login-links {
  color: $orange;
  text-decoration: underline;
  font-weight: 599;
  @include darken-on-hover($orange);
}

.create-account {
    text-align: center;
}

.login-form {
    margin-bottom: 19px;
}

.submit-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
        margin: 1rem 0;
        p {
            font-size: .8rem;
            margin: 0 !important;
        }
        :last-child {
            text-decoration: underline;
            cursor: pointer;
            @include darken-on-hover($orange)
        }
    }
    :last-child {
        margin: 0 !important;
        justify-content: center;
    }
}

.legal-text {
    font-size: 12px;
    padding-bottom: 2em;
    
    p {
        margin-bottom: .25em;
        text-align: center;
    }
}

// Dark Styling

.form--dark {
  .legal-text {
    color: white;
  }
}   
// ---------------------- client styles ---------------------------------------

// styles for app client
div[class*="App__app"] {    
    padding: 1em;

    .create-account {
        margin-top: 1em;
    }

    .password-list {
        columns: 2;
        column-gap: 1.5em;
    }
}

// styles for web clients
div[class*="App__top-frame"], div[class*="App__pop-frame"], div[class*="App__iframe"] {
    .legal-text {
        p {
            &.login-text {
                margin-bottom: 1em;
            }
        }   
    }
}
// ---------------------styles for styled forms ------------------------------------

// styles for styled version of forms
div[class*="styled"] {
    /*
        sometimes the form wrapper wraps all the content and sometimes it doesn't
        .content-wrapper and .form-wrapper will both act as flexboxes with the same flex flow
        to handle both cases
    */
    .content-wrapper {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
    }

    .form-wrapper {
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
    }

    #login-form {
       flex-basis: 67%; 
    }

    #forgot-password-form {
        flex-basis: 75%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;
    }

    #create-account-form {
        flex-basis: 65%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;
    }

    .switcher-logo-wrapper {
        display: flex;
        width: 100%;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        padding-top: 8%;
        margin-bottom: 6%;
    }

    .login-prompt {
        color: #FFFFFF;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
    }

    .submit-wrapper {
        span {
            p {
                color: $input-text-color;
            }
            :last-child {
                text-decoration: underline;
                cursor: pointer;
                @include darken-on-hover($orange)
        }
        }
    }

    div[class*="input-container"] {
        margin-top: 1em;
        background: $input-background-color !important;
        border-radius: 8px;
        border: none;
        color: $input-text-color;
    }

    input[class*="form-control"], select[class*="form-control"], span[class*="form-control"] {
        background-color: $input-background-color !important;
        color: $input-text-color !important;
        border: none !important;
    }
    
    :global {
        .PhoneInput {
            input {
                color: $input-text-color !important;
                background-color: $input-background-color !important;
                &::placeholder {
                    color: $input-text-color !important;
                }
            }
        }
    }

    .login-input select:focus {
        color: $input-text-color !important;
    }

    input:-internal-autofill-selected {
        background-color: transparent !important;
    }

    .login-form {
        width: $login-content-width;
        margin: 0 auto;
    }

    input:focus {
        color: #FFFFFF !important;
    }

    input:-webkit-autofill, input:-webkit-autofill:hover {
        -webkit-text-fill-color: $input-text-color !important;
    }

    label[class*="floating-label"] {
        color: $input-text-color;
    }

    a[class*="login-links"] {
        color: #FFFFFF;
        text-decoration: none;
        font-size: 14px;
        line-height: 39px;
        width: max-content;
    }

    .account-links {
        padding-bottom: 0;
        padding-top: 1em;
        margin: 0 auto 1em auto;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
    }

    .legal-text {
        width: $login-content-width;
        margin: 0 auto;
        font-size: 10px;

        p {
            margin: 0 1em;
            color: #FFFFFF;
        }

        a {
            font-size: 12px;
        }
    }

    p[class*="create-account"] {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .password-rules {
        color: $input-text-color;
    }

    button[type="submit"] {
        min-width: 240px;
        height: 48px;
        margin-top: 6%;
    }

    button[id="forgot-password-submit"] {
        margin-top: 10%;
    }
}


    


