.loading-container {
  display: flex;
  justify-content: center;
  padding-top: 4rem;
  position: fixed;
  z-index: 2000;
  background-color: #00000066;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  transition: 0.5s;
  visibility: collapse;
  opacity: 0;
}

.active {
  visibility: visible;
  opacity: 1;
}
