@import "../../styles/variables.scss";

.login-input input select {
  color: black;
  background-color: transparent;
  border-radius: 0;
  font-size: 16px;
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.login-input input:focus {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important; 
  color: black !important;
}

.login-input select:focus {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  color: black !important;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
  color: black;
  background-color: white; 
  border: 2px solid #ccc;
  transition: border .25s;
  margin-top: .75em;
  border-radius: 6px;
  height: 55px;

  &:focus-within {
    border-color: $teal;
  }
}

.coupon-input {
  height: 60px;
}


div[class*="styled"] {
  .profile-icon, .lock-icon {
    fill: rgba(235, 235, 245, 0.6);
    margin-left: 6px;
    color: rgba(235, 235, 245, 0.6);
  }
}

.profile-icon {
  fill: inherit;
  width: 18px;
  margin-left: 6px;
}

.lock-icon {
  fill: inherit;
  width: 15px;
  margin-left: 6px;
}

.work-icon {
  width: 15px;
  margin-left: 6px;
}

.coupon-icon {
  fill: inherit;
  width: 18px;
  cursor: pointer;
  transition: transform .25s;
  margin-left: 6px;
}

.remove-icon {
  transform: rotate(135deg);
}

.input-container button {
  opacity: 0;
  transition: opacity .25s;
}

.floating-label {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
  transition: top .25s, left .25s;
  font-size: 14px;
}

.has-focus {
  top: 1px;
  left: 4px;
  transform: none;
  font-size: .7em;
  color: $teal;
}

.not-focused {
  // border-bottom: 1px solid transparent;
}

// Auto fill styles for webkit browsers

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $blue--dark;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

//  Error Styling

.input-container--error {
  border-color: #dc3545;
  // background-color: lighten(#f8d7da, 5%);
}

.select-list {
  height: 45px;
}