@use "sass:color";
/// COLORS
// Primary
$orange: #ed5623;

// Secondary
$blue: #0c4f60;
$blue--dark: #042c3b;
$orange--light: #f37022;
$gray--light: #f4f5f6;
$--theme-secondary--light: color.scale(#a6078a, $lightness: 50%);

// Tertiary
$teal: #138f99;
$teal--light: #1ebdc1;
$gray: #515151;
$gray--dark: #3f4e57;


// Misc
$text-color: #515251;
$gray--medium: #ddd;

$error: #dc3545;

/// BREAKPOINTS
$bp-sm: 550px;
$bp-md: 800px;
$bp-lg: 1073px;


/// TRANSITIONS
$transition-quick: 0.1s;
$transition: 0.25s;
$transition-long: 0.5s;
