@import '../../../styles/variables.scss';

.container {
    & input {
        border: none;
        outline: none;
    }
}

.profile-page {
    & span {
        border-radius: 8px;
    }
    & > span:focus-within {
        outline: none;
        border-color: var($orange);
        box-shadow: 0 0 0 0.2rem rgba(237, 86, 35, 0.6);
    }
}

:global {
    .PhoneInput {
        width: 100%;
        > input::placeholder {
            color: black;
        }
    }
}

.create-account {
    position: relative;
    color: black;
    border: none;
    margin-top: 0.75em;

    & input {
        font-size: 14px;
        font-weight: 500;
    }

    & span {
        display: flex;
        border: 2px solid #ccc;
        border-radius: 8px;
        transition: border $transition;
        border-radius: 6px;
        height: 55px;
        align-items: center;
    }

    &:focus-within {
        border-color: var($teal);
    }

    & .floating-label {
        position: absolute;
        transform: translateY(-50%);
        transition:
            top $transition,
            left $transition;
        font-size: 14px;
        margin-top: 1px;
        margin-left: 4px;
    }

    & .has-focus {
        top: 1px;
        left: 4px;
        transform: none;
        font-size: 0.7em;
        color: var($--theme-secondary--light);
    }
}
