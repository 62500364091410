.sk-spinner {
  color: #ed5623;
}

.sk-spinner > div {
  background-color: currentColor;
}

.sk-double-bounce {
  width: 8rem;
  height: 8rem;
  position: relative;
}

.sm {
  width: 2rem;
  height: 2rem;
}

.md {
  width: 4rem;
  height: 4rem;
}

.sk-double-bounce > div {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: currentColor;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.sk-double-bounce > div:last-child {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-fade-in-quarter-second {
  -webkit-animation: sk-fade-in 0.5s;
  -moz-animation: sk-fade-in 0.5s;
  -o-animation: sk-fade-in 0.5s;
  -ms-animation: sk-fade-in 0.5s;
  animation: sk-fade-in 0.5s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@-webkit-keyframes sk-fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes sk-fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes sk-fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes sk-fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}