@import "./styles/variables.scss";

html {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // height: 100vh;
}

html,
body {
  background-color: transparent;
}

div#root, div[class*="App"] {
  height: 100vh;
}

div[class*="App"] {
  overflow-y: auto;
}

.styled {
  background-color: #042C3B;
}

code,
pre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h4 {
  font-weight: 600;
}

/* BOOTSTRAP OVERRIDE */

form {
  .row {
    margin-right: 0;
    margin-left: 0;

    @media (max-width: 350px) {
      display: block;

      > * {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .col {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}

// unstyled checkbox
.form-check {
  max-width: 95%;
  height: max-content;
  margin: 1.5rem auto;
  max-width: unset;
  flex-flow: row nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  
  label {
    font-weight: 400;
    font-size: 14px;
  }
  
  .form-check-input {
    width: 20px;
    height: 20px;
    border-radius: unset;
    background-color: #ffffff;
    margin-top: .2em;
    border: 2px solid #cccccc;
  }

  .form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23212529' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
}

// styled checkbox
div[class*="styled"] {
  .form-check {
    margin: 1rem auto 0 auto;
    color: #ffffff;

  }
  .form-check-input {
    border: 1px solid #ffffff;
    background-color: transparent;
  }

  .form-check-input:checked[type=checkbox] {
    background-image:  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
}

label {
  font-weight: 500;
}

.btn,
input[type="button"] {
  background-color: $orange;
  border-radius: 24px;
  cursor: pointer;
  color: #ffffff;
  font-size: 0.8em;
  font-weight: 600;
  font-variation-settings: "wght" 600;
  padding: 0.6em 1.2em;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 2px solid $orange;
  -webkit-transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;
  margin: 0.5em 0.5em 0.5em 0;

  small {
    text-transform: none;
    font-weight: 400;
    font-variation-settings: "wght" 400;
    display: block;
  }

  &.btn-noBg {
    background-color: transparent;
    color: $orange;
    border-color: transparent;
  }

  &.btn--large {
    font-size: 1.1em;
    padding: 0.4em 1.8em;
    border-radius: 2em;

    @media (min-width: $bp-md) {
    }
  }

  &:disabled,
  &[disabled] {
    background-color: gray;
    border-color: gray;
    cursor: not-allowed;
  }
}

.btn:hover,
.btn:focus,
input[type="button"]:hover,
input[type="button"]:focus {
  color: white;
  border-color: $teal--light;
  background: $teal--light;
  text-decoration: none;
}

.btn:active,
input[type="button"]:active {
  position: relative;
  top: 1px;
}

.btn--tertiary {
  color: $orange;
  background-color: transparent;
  border-radius: 0;
  border: none;
  border-bottom: 2px solid currentColor;

  &:hover,
  &:focus {
    color: white;
  }
}

.btn-center {
  margin: 0 auto;
}

.btn-ghost {
  background: transparent;
  border: 2px solid #ffffff;
  border-radius: 24px;

  &.btn-noBg {
    color: white;
  }
}

.btn--icon {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 0.8em;
    width: auto;
    margin-left: 0.5em;
  }
}

.btn-orange {
  color: $orange;
  border-color: $orange;
}

button[type="submit"] {
  margin-left: auto;
  margin-right: auto;
  display: block;
  min-width: 200px;
}


/* Nav */
.nav-pills .nav-link.active {
  background-color: #ed5623 !important;
}

.nav-link {
  cursor: pointer;
}

/* Input */
.form-control {
  border-color: transparent;
  border-radius: 2rem;
}

.form-control:focus {
  border-color: #ed5623;
  box-shadow: 0 0 0 0.2rem rgba(212, 53, 0, 0.25);
}

.input-group-text {
  border-right: none;
  background-color: inherit;
  color: inherit;
}

.input-group input,
.input-group select {
  border-left: inherit;
  background-color: inherit;
  color: inherit;
}

select {
  appearance: none;
  background-color: #fff;
  background-image: url("assets/icons/down.svg");
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 25px;
  background-position-x: 98.5%;
  padding: .375rem .75rem;
  height: 30px;
  border: 1px solid #f2f2f3;
  border-radius: 0 2rem 2rem 0;
  flex: 1 1 auto;
}

/* Progress Bar */
.progress {
  flex-grow: 1;
}

// Error Styling
.text-danger {
  font-weight: bold;
}

.alert {
  font-size: 12px;
}

button.close {
  background-color: transparent;
  opacity: .5;
  transition: .2s all ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em;
  line-height: 1em;

  &:hover,
  &:focus {
    opacity: 1;
  }

  span {
    font-size: 2rem;
  }
}

// Dark Styling

.form--dark {
  color: white; 
}