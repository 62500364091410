.notification-container {
  z-index: 1000;
  visibility: collapse;
  transition: 0.5s;
}

.fixed {
  position: fixed;
  top: 1rem;
  right: -4rem;
}

.show {
  visibility: visible;
  right: 4rem;
}
